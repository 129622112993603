import { throttle, debounce } from './utils';

let isSticky = false;

function StickyHeader() {
  const header = document.querySelector('.c-header');
  if (!header) return;

  OnScroll();
  window.addEventListener('scroll', throttle(OnScroll, 100));
  window.addEventListener('scroll', debounce(OnScroll, 100));
}

function OnScroll() {
  const matches = header.getBoundingClientRect().top === 0;

  if (!isSticky && matches) {
    isSticky = true;
    document.body.classList.add('is-sticky')
  } else if (isSticky && !matches) {
    isSticky = false;
    document.body.classList.remove('is-sticky')
  }
}

export default StickyHeader;

function Newsletter() {
  const newsletter = Array.from(document.querySelectorAll('.js-newsletter'));
  newsletter.forEach(SetupNewsletterForm);

  const newsletterLinks = Array.from(document.querySelectorAll('[href="#newsletter-modal"]'));
  newsletterLinks.forEach(SetupNewsletterLinks);
}

const OPEN_CLASS = 'newsletter-open';

function SetupNewsletterForm(el) {
  el.addEventListener('submit', e => {
    e.preventDefault();
    openModal(e.target.email.value);
  });
}

function SetupNewsletterLinks(el) {
  el.addEventListener('click', e => {
    e.preventDefault();
    openModal();
  });
}

function openModal(email = '') {
  const modal = document.getElementById('newsletter-modal');
  if (!modal) return;

  const emailField = modal.querySelector('[type="email"]');
  if (!emailField) return;

  const firstField = modal.querySelector('input,select,textarea');
  if (!firstField) return;

  emailField.value = email;
  document.body.classList.add(OPEN_CLASS);
  setTimeout(() => {
    firstField.focus();
  }, 0);

  const close = modal.querySelector('.c-newsletter-modal__close');
  if (close) {
    close.addEventListener('click', (e) => {
      e.preventDefault();
      closeModal();
    });
  }

  const fullClose = modal.querySelector('.c-newsletter-modal__full-close');
  if (fullClose) {
    fullClose.addEventListener('click', (e) => {
      e.preventDefault();
      closeModal();
    });
  }

  window.addEventListener('keydown', modalKeydown);
}

function modalKeydown(e) {
  if (e.code === 'Escape') {
    closeModal();
  }
}

function closeModal() {
  document.body.classList.remove(OPEN_CLASS);
  window.removeEventListener('keydown', modalKeydown);
}

export default Newsletter;

import { gsap } from "gsap";

import Accordion from "./imports/accordion";
import Slider from "./imports/slider";
import InlineInput from "./imports/inline-input";
import Newsletter from "./imports/newsletter";
import StickyHeader from "./imports/sticky-header";
import Modal from "./imports/modal";
import Rack from "./imports/rack";
import AnimBowl from "./imports/anim-bowl";
import AnimHero from "./imports/anim-hero";
import AnimNewsletter from "./imports/anim-newsletter";
import AnimHand from "./imports/anim-hand.js";
import AnimTea from "./imports/anim-tea.js";
import AnimCards from "./imports/anim-cards.js";
import AnimTestimonials from "./imports/anim-testimonials.js";
import AnimBits from "./imports/anim-bits-and-bobs.js";
import AnimCreator from "./imports/anim-creator.js";
import AnimCTA from "./imports/anim-cta.js";
import { InjectMeal } from "./imports/anim-meal";
import { AnimMeal } from "./imports/anim-meal";
import AnimPasta from "./imports/anim-pasta";
import AnimBite from "./imports/anim-bite";
import AnimNoodles from "./imports/anim-noodles";
import AnimCatch from "./imports/anim-catch";
import AnimMunch from "./imports/anim-munch";
import AnimBeats from "./imports/anim-beats";
import Testimonials from "./imports/testimonials";
import Beats from "./imports/beats";
import Toc from "./imports/toc";
import ArticleTags from "./imports/article-tags";

(() => {
  if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
  }
  if (window.HTMLCollection && !HTMLCollection.prototype.forEach) {
    HTMLCollection.prototype.forEach = Array.prototype.forEach;
  }

  InjectMeal();
  Accordion();
  Slider();
  InlineInput();
  Newsletter();
  StickyHeader();
  Modal();
  Rack();
  Testimonials();
  Beats();
  Toc();
  ArticleTags();

  // animation check handled in file.
  AnimBowl();
  AnimHero();
  AnimBeats();

  let motionQuery = window.matchMedia(
    "(prefers-reduced-motion: no-preference)"
  );
  let animationToggle = document.querySelector(".c-animation-toggle-input");

  let url = window.location.href;
  if (animationToggle) {
    // check if the user's visited and used the motion toggle before
    let localMotionPreference = localStorage.getItem("motionPreference");

    // if the users specified no motion on the toggle then use that, otherwise use the OS setting
    localMotionPreference === "false"
      ? (animationToggle.checked = false)
      : (animationToggle.checked = motionQuery.matches);

    // adjust when OS setting changes
    motionQuery.addEventListener("change", () => {
      animationToggle.checked = motionQuery.matches;
    });

    // update local storage & GSAP if the toggle is used
    animationToggle.addEventListener("change", () => {
      localStorage.setItem("motionPreference", animationToggle.checked);
      gsap.matchMediaRefresh();
    });

    // hard nope on the subpages
    if (url.slice(url.length - 9) !== "articles/") {
      animationToggle.checked = false;
    }

    AnimNoodles(animationToggle);
    AnimMeal(animationToggle);
    AnimPasta(animationToggle);
  }

  AnimCatch();
  AnimBite();

  // rest of the animation doodahs
  if (motionQuery.matches) {
    AnimNewsletter();
    AnimHand();
    AnimTea();
    AnimCards();
    AnimTestimonials();
    AnimBits();
    AnimCreator();
    AnimCTA();
    AnimMunch();
  }
})();

import { gsap } from 'gsap';

function AnimCTA() {
  const ctas = gsap.utils.toArray('.c-hero__cta');

  if (!ctas.length > 0) return;

  ctas.forEach((cta) => {
    const q = gsap.utils.selector(cta);


    let lick = gsap.timeline({paused: true})
    
    lick.to(q('.anim-tongue'), {
      xPercent: -140,
      yPercent: 4,
      rotate: 35,
      duration: 0.4,
      transformOrigin: 'bottom center',
      ease: "back.inOut(1.3)",
    })
  

    cta.addEventListener('mouseenter', (e) => {
      lick.play(0)
    })

    cta.addEventListener('mouseleave', (e) => {
      lick.reverse()
    })
  });
}

export default AnimCTA;
